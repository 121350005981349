<template>
  <el-dialog
    class="print-student-order-dialog"
    title="批量导出"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="420px"
    :before-close="close"
  >
    <el-form ref="form" :model="formData" label-width="100px" :rules="rules">
      <el-form-item label="年级" prop="grade">
        <el-select v-model="formData.grade" clearable filterable>
          <el-option
            v-for="{paramValue,id} in $store.state.systemParam.grade"
            :key="id"
            :label="paramValue"
            :value="Number(paramValue)" />
        </el-select>
      </el-form-item>
      <el-form-item label="学年" prop="year">
        <el-select v-model="formData.year" clearable>
          <el-option
            v-for="num in 3"
            :key="num"
            :label="`第${num}学年`"
            :value="num" />
          <el-option label="汇总" :value="0" />
        </el-select>
      </el-form-item>
      <el-form-item label="缴费状态" prop="payStatus">
        <el-select ref="payStatusRef" v-model="formData.payStatus">
          <el-option
            v-for="{name,type} in payStatusList"
            :key="type"
            :label="name"
            :value="type" />
        </el-select>
      </el-form-item>
      <el-form-item label="院系">
        <el-select v-model="formData.collegeId" :disabled="!!$store.state.login.userInfo.collegeId">
          <el-option label="全校" value="全校" />
          <el-option
            v-for="{collegeName, id} in collegeList"
            :label="collegeName"
            :value="id"
            :key="id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="success" @click="handleExport">开始导出</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import CampusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'

export default {
  mixins: [dialog, CampusSchMajorClasses],
  data() {
    return {
      formData: {
        grade: new Date().getFullYear(),
        payStatus: 1,
        year: 1,
        collegeId: '全校'
      },
      payStatusList: [
        {
          type: 1,
          name: '已缴清'
        },
        {
          type: 2,
          name: '未缴清'
        },
        {
          type: 3,
          name: '未缴费'
        },
        {
          type: 4,
          name: '已缴费'
        },
        {
          type: 5,
          name: '需退费'
        },
        {
          type: 6,
          name: '汇总'
        }
      ],
      rules: {
        payStatus: {
          required: true,
          message: '请选择缴费状态',
          trigger: 'blur'
        },
        grade: {
          required: true,
          message: '请选择年级',
          trigger: 'blur'
        },
        year: {
          required: true,
          message: '请选择学年',
          trigger: 'blur'
        }
      }
    }
  },
  mounted() {
    this.getCollegeList()
    this.formData.collegeId = this.$store.state.login.userInfo.collegeId || '全校'
  },
  methods: {
    // 批量导出
    handleExport() {
      const _payStatus = this.$refs.payStatusRef.selectedLabel || ''
      this.$http.exportExcelHttp
        .exportStudentPayStatus(
          {
            ...this.formData,
            collegeId: this.formData.collegeId === '全校' ? null : this.formData.collegeId
          },
          `${this.formData.grade ? this.formData.grade + '级' : ''}${this.formData.year ? '第' + this.formData.year + '学年' : '汇总'}${_payStatus}名单`
        )
        .then(() => {
          this.close()
        })
    }
  }
}
</script>
