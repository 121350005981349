<template>
  <table-view v-loading="loading" class="studentOrderCount-container" :show-page="false">
    <template #header>
      <view-search-form
        :queryInfo.sync="queryInfo"
        :tool-list="[ 'grade', 'year', 'college']"
        :insert-select-all="[ 'college']"
        @on-search="renderTable" />
      <div class="header-button">
        <el-button type="success" size="small" @click="showDialog"
        >导出缴费统计
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      stripe
      class="table-header"
      show-summary
    >
      <el-table-column type="index" label="ID" width="50" align="center" />
      <el-table-column label="年级" width="80" align="center">
        <template v-slot="{row}">
          <span>{{ row.grade }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="collegeName" label="院系" min-width="160" />
      <el-table-column
        prop="oneYearPeopleNum"
        label="总人数" />
      <el-table-column
        prop="oneYearUnPayNum"
        label="未缴费人数" />
      <el-table-column
        prop="oneYearPayNum"
        label="已缴费人数" />
      <el-table-column label="已缴费学生学籍人数">
        <el-table-column prop="stuNum" label="在读" />
        <!--  非在读人数包括：
        1.未报到（已缴费）
        2.退学（已缴费）
        3.休学（已缴费）
        的学生。 -->
        <el-table-column prop="noStudyPayNum" label="非在读" />
      </el-table-column>
      <el-table-column label="已缴费各情况统计">
        <el-table-column
          prop="oneYearPaidNum"
          label="已缴清人数" />
        <el-table-column
          prop="oneYearOwnNum"
          label="未缴清人数" />
        <el-table-column
          prop="oneYearRefundNum"
          label="需退费人数" />
        <el-table-column
          prop="dormitoryPriceNullNum"
          label="未分配宿舍人数" />
      </el-table-column>
    </el-table>
    <div style="padding-left: 1rem;padding-bottom: 1rem;">
      <p style="color: #606266; font-size: 14px">
        1、总人数 = 已缴费人数 + 未缴费人数
      </p>
      <p style="color: #606266; font-size: 14px">
        2、已缴费人数 = 在读人数 + 非在读已缴费人数
      </p>
      <p style="color: #606266; font-size: 14px">
        3、已缴费人数 = 已缴清人数 + 未缴清人数 + 需退费人数 +
        已缴费未分配宿舍人数
      </p>
    </div>
    <!--  批量导出  -->
    <selectPayStatus ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" />
  </table-view>
</template>

<script>
import { getStudentOrderCountApi } from '@/api/finance-manage/studentOrder'
import selectPayStatus from './dialog/selectPayStatus.vue'

export default {
  name: 'studentOrderCount',
  components: {
    selectPayStatus
  },
  data () {
    return {
      loading: false,
      visibleDialog: false,
      queryInfo: {
        campusId: null,
        collegeId: null,
        grade: new Date().getFullYear(),
        year: 1
      },
      tableData: [],
      total: 0
    }
  },
  methods: {
    // 获取学生列表
    renderTable () {
      this.loading = true
      getStudentOrderCountApi(this.queryInfo).then((res) => {
        this.tableData = res.data.list
        this.total = res.data.total
      }).catch(() => {
        this.loading = false
      }).finally(() => {
        this.loading = false
      })
    },
    showDialog () {
      this.visibleDialog = true
    }
  }
}
</script>
